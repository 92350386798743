import { isNil } from '@/shared/utils';
import { store } from '@/store';

export default {
	name: 'FilePreviewerMixin',
	data() {
		return {
			isUploadFileLoading: false
		};
	},
	methods: {
		prepareDownload(buffer) {
			if (isNil(buffer)) return;

			const supportsDownloadAttribute = HTMLAnchorElement.prototype.hasOwnProperty('download');

			const blob = new Blob([buffer], { type: 'application/pdf' });

			return blob;
		},

		async updatePdfFile(blob) {
			try {
				this.isUploadFileLoading = true;

				const file = new File([blob], blob.name, {
					type: blob.type,
					lastModified: new Date().getTime()
				});

				let formData = new FormData();
				formData.set('uploadimage', file);
				formData.append('xid', this.xid);
				formData.append('userId', await store.userID());

				await this.$axios.$post('upload', formData, {
					onUploadProgress: (pe) => console.log({ 'pe.loaded': pe.loaded }),
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'content-type': 'multipart/form-data'
					}
				});

				this.isUploadFileLoading = false;
			} catch (e) {
				console.log({ e });
			}
		}
	}
};
