import Vue from 'vue';
import { isNil, omit, getObjectFromValue } from '@/shared/utils';
import { getImgCommentList, getImgCommentStats, addImgComment } from '@/cruds/images.crud';
import { delItemComment } from '@/cruds/itemcomment.crud';
// import { getTreeView, getDeepTree } from '@/components/Packages/FIlesPreviewer/imageShowroom.utils';

const authorizedFileTypes = ['image', 'doc'];

const defaultComment = { message: null, docDeliId: 0 };
const commentStatsDefault = { likes: 0, disLikes: 0, comments: 0 };
const optionsDefault = {
	hideCropButton: false,
	hideLikeButton: false,
	hideLikeDislike: false,
	hideDisLikeButton: false,
	hideCommentButton: false,
	hideDeleteButton: false,
	showSignFileButton: false,
	showStatusFileTag: false,
	annotationEnabled: true,
	presentationMode: authorizedFileTypes[0],
	zIndex: 1141
};

const ImageShowRoomManager = function (self, initialState = {}) {
	if (!self) throw new Error('ImageShowRoom is required.');

	this.imagesShowroom = self;

	this.states = {
		isCarouselControlActive: true,
		isCommentPanelOpen: false,
		isElementPositioningLoad: false,
		imagesList: [],

		isPinModalOpen: false,
		isCommentComposerFocus: false,

		currPinHovered: null,

		currentActiveSlide: null,
		currentActiveIndex: 0,
		currentActiveSlideExt: null,
		currFileDetails: null,

		isCommentListLoading: false,
		isCommentSendingLoading: false,
		isCommentStatsLoading: false,
		hasCommentsActive: true,
		commentsList: [],
		pinsList: [],

		isAnnotationToolActive: false,
		currAnnotationSlide: null,
		positionedAnnotationWaiting: null,

		options: Object.assign({}, {}, optionsDefault),
		commentStats: Object.assign({}, commentStatsDefault),
		commentPayload: Object.assign({}, defaultComment),

		hasDocumentChangesCanBeSubmit: false,

		isUploadFileLoading: false
	};

	for (let prop in initialState) {
		if (initialState.hasOwnProperty(prop) && this.states.hasOwnProperty(prop)) {
			this.states[prop] = initialState[prop];
		}
	}

	/**
	 * @param {String} mutation
	 * @return {Function}
	 */
	this.commit = (mutation) => {
		const _m = Object.getPrototypeOf(this).mutations;
		return _m[mutation];
	};
};

ImageShowRoomManager.prototype.mutations = {
	/**
	 * @param {Object} managerContext
	 * @param {Array} images
	 */
	setImagesList({ states }, images) {
		states.imagesList = images;
	},

	/**
	 * @param {Object} managerContext
	 * @param {String|null} xid
	 */
	setCurrentActiveSlide({ states }, xid) {
		states.currentActiveSlide = xid;

		states.currFileDetails = getObjectFromValue(states.imagesList, 'xid', xid);

		const index = states.imagesList.findIndex((_) => _.xid === xid);
		states.currentActiveIndex = index;
	},

	/**
	 * @param {Object} managerContext
	 * @param {Boolean} status
	 */
	setPinModalCommentState({ states }, status) {
		states.isPinModalOpen = status;
	},

	/**
	 * @param {Object} managerContext
	 * @param {Boolean} status
	 */
	toggleCommentComposerFocusStatus({ states }, status) {
		states.isCommentComposerFocus = status;
	},

	/**
	 * @param {Object} managerContext
	 * @param {Boolean} status
	 */
	setCurrentActiveSlideStatus({ states }, status) {
		states.isCarouselControlActive = status;
	},

	/**
	 * @param {Object} managerContext
	 * @param {Boolean} status
	 */
	setUploadFileLoadingStatus({ states }, status) {
		states.isUploadFileLoading = status;
	},

	/**
	 * @param {Object} managerContext
	 * @param {Number} code
	 */
	setCurrPinHovered({ states }, code = null) {
		states.currPinHovered = code;
	},

	/**
	 * @param {Object} managerContext
	 * @param {Boolean} status
	 */
	setCommentPanelOpen({ states }, status) {
		if (!status) states.pinsList = [];
		states.isCommentPanelOpen = status;
	},

	/**
	 * @param {Object} managerContext
	 * @param {Boolean} status
	 */
	setCommentPayload({ states }, payload) {
		states.commentPayload = payload;
	},

	/**
	 * @param {Object} managerContext
	 * @param {String|null} xid
	 */
	setAnnotationToolActive({ states }, xid = null) {
		const _xid = isNil(xid) ? states.currentActiveSlide : xid;
		states.isAnnotationToolActive = true;
		states.currAnnotationSlide = _xid;
	},

	/**
	 * @param {String|null} xid
	 */
	destroyAnnotationTool({ states }) {
		states.isAnnotationToolActive = false;
		states.isElementPositioningLoad = false;
		states.currAnnotationSlide = null;
	},

	toggleAnnotationToolActive({ states }, status = null) {
		const _s = isNil(status) ? !states.isAnnotationToolActive : status;
		states.isAnnotationToolActive = _s;
	},

	/**
	 * @param {Object} managerContext
	 * @param {String} xid
	 */
	async getCommentStats({ states, commit }, xid) {
		if (isNil(xid)) return;
		states.isCommentStatsLoading = true;

		const stats = await getImgCommentStats(xid);
		if (isNil(stats)) {
			states.commentStats = Object.assign({}, commentStatsDefault);
			states.isCommentStatsLoading = false;
			return;
		}

		const { sumDoLike: likes, sumDoNotLike: disLikes } = stats;

		states.commentStats = { likes, disLikes, comments: 0 };
		states.isCommentStatsLoading = false;
	},

	/**
	 * @param {Object} managerContext
	 * @param {String} xid
	 */
	async getCommentsList({ states }, { xid, docDeliId = 0 }) {
		if (isNil(xid)) return;
		states.isCommentListLoading = true;
		const list = await getImgCommentList(xid, docDeliId);

		states.commentsList = list;
		states.pinsList = list.filter((l) => l.pinInfoCode !== 0);
		states.isCommentListLoading = false;
	},

	/**
	 * @param {Object} managerContext
	 * @param {String} xid
	 */
	async addComment({ states }, msgPayload) {
		states.isCommentSendingLoading = true;

		const hasPosition = !isNil(states.positionedAnnotationWaiting);
		const payload = msgPayload;

		if (hasPosition) {
			payload.pos = {
				x: states.positionedAnnotationWaiting.coord_x,
				y: states.positionedAnnotationWaiting.coord_y,
				code: states.pinsList.length + 1
			};
		}

		const comments = await addImgComment(payload);
		if (isNil(comments)) return;
		states.commentsList = comments;
		states.pinsList = comments.filter((c) => c.pinInfoCode !== 0);

		if (hasPosition) {
			states.isElementPositioningLoad = false;
			states.positionedAnnotationWaiting = null;
		}

		states.isCommentSendingLoading = false;
	},

	/**
	 * @param {Object} managerContext
	 */
	async addLike({ states }) {
		states.isCommentSendingLoading = true;
		const comments = await addImgComment({ xid: states.currentActiveSlide });
		states.isCommentSendingLoading = false;
	},

	/**
	 * @param {Object} managerContext
	 */
	async addDisLike({ states }) {
		states.isCommentSendingLoading = true;
		const comments = await addImgComment({
			xid: states.currentActiveSlide,
			like: false
		});
		states.isCommentSendingLoading = false;
	},

	/**
	 * @param {Object} managerContext
	 * @param {Object} options
	 */
	initShowRoomOptions({ states }, options = {}) {
		const _o = omit(['presentationMode'], options);
		const presentationModesOption = authorizedFileTypes.includes(options.presentationMode) ? { presentationMode: options.presentationMode } : {};
		states.options = Object.assign({}, optionsDefault, _o, presentationModesOption);
	},

	/**
	 * @param {Object} managerContext
	 * @param {Object} pos
	 */
	setPositionedAnnotationWaiting({ states }, pos) {
		states.isElementPositioningLoad = !isNil(pos);
		states.positionedAnnotationWaiting = pos;
	},

	/**
	 *
	 * @param {Object} managerContext
	 * @param {Number} id - commentId
	 */
	async delCommentItem({ states }, id) {
		await delItemComment(id);
		const list = states.commentsList;
		const index = list.findIndex((c) => c.id === id);
		states.commentsList.splice(index, 1);
	}
};

/**
 * sending emit on the table
 * @param {String} key
 * @param {*} content
 * @returns {this | *}
 */
ImageShowRoomManager.prototype.emit = function (key, content) {
	return this.imagesShowroom.$emit(key, content);
};

ImageShowRoomManager.prototype.stream = new Vue();

/**
 * use store mutaions with 'commit' function
 * @param {String} name
 * @param {*} args
 */
ImageShowRoomManager.prototype.dispatch = function (name, ...args) {
	const { mutations, states, commit } = this;
	if (mutations[name]) {
		const managerContext = { commit, states };
		mutations[name].apply(this, [managerContext].concat(args));
	} else {
		throw new Error(`Action not found: ${name}`);
	}
};

export default ImageShowRoomManager;
